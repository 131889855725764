const logo = () => (
  <svg width="66" height="53" viewBox="0 0 66 53">
    <path
      d="M41.8971 14.5927H43.9009L49.6123 8.86943L49.8924 6.43863C39.2623 -2.9644 23.039 -1.94861 13.6553 8.70342C11.048 11.6599 9.15867 15.1816 8.12524 18.9918C8.76029 18.731 9.46634 18.6875 10.129 18.8732L21.5519 16.9839C21.5519 16.9839 22.1318 16.0195 22.4355 16.0788C27.5159 10.486 36.0673 9.83384 41.9365 14.5927H41.8971Z"
      fill="#EA4335"
    />
    <path
      d="M57.7496 18.9919C56.4361 14.1461 53.7421 9.79042 49.9949 6.4624L41.9799 14.4939C45.3642 17.2646 47.293 21.4424 47.2102 25.8218V27.2487C51.1506 27.2487 54.3456 30.4502 54.3456 34.3988C54.3456 38.3473 51.1506 41.5489 47.2102 41.5489H32.9394L31.5154 42.9955V51.5725L32.9394 52.9993H47.2102C57.4577 53.0784 65.8316 44.8176 65.9105 34.549C65.9578 28.3119 62.8931 22.478 57.7496 18.9919Z"
      fill="#4285F4"
    />
    <path
      d="M18.6489 52.9084H32.9197V41.4619H18.6489C17.6312 41.4619 16.6294 41.2405 15.7024 40.8176L13.6987 41.4421L7.94778 47.1654L7.44684 49.1732C10.6694 51.6159 14.6059 52.9281 18.6489 52.9084Z"
      fill="#34A853"
    />
    <path
      d="M18.6489 15.7785C8.39744 15.8378 0.141834 24.2131 0.201 34.4857C0.236499 40.2208 2.90685 45.62 7.44684 49.114L15.7222 40.8216C12.1328 39.1971 10.5353 34.96 12.1564 31.3632C13.7776 27.7665 18.006 26.1657 21.5954 27.7902C23.1771 28.5056 24.4432 29.7783 25.1611 31.3632L33.4364 23.0709C29.918 18.4543 24.4432 15.7548 18.6489 15.7785Z"
      fill="#FBBC05"
    />
  </svg>
)
export default logo
